<template>
    <app-layout>
        <div>
            <div class="">
                <div class="support-wrapper">
                    <div class="support-bg mb-20">
                        <p class="text-center text-white text-5xl font-bold px-4">{{ $t('support.title') }}</p>
                        <ul class="list-container flex gap-6 justify-center max-w-2xl mx-auto mt-6 flex-wrap text-white text-2xl">
                            <li class="flex items-center gap-4">
                                <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M25.3353 6.66699H6.66862C5.19586 6.66699 4.00195 7.8609 4.00195 9.33366V22.667C4.00195 24.1398 5.19586 25.3337 6.66862 25.3337H25.3353C26.808 25.3337 28.002 24.1398 28.002 22.667V9.33366C28.002 7.8609 26.808 6.66699 25.3353 6.66699Z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M4.00195 9.33301L16.002 17.333L28.002 9.33301" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <a>info@skygowifi.com</a>
                            </li>
                            <li class="flex items-center gap-4">
                                <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.66862 5.33301H12.002L14.6686 11.9997L11.3353 13.9997C12.7632 16.895 15.1066 19.2384 18.002 20.6663L20.002 17.333L26.6686 19.9997V25.333C26.6686 26.0403 26.3877 26.7185 25.8876 27.2186C25.3875 27.7187 24.7092 27.9997 24.002 27.9997C18.801 27.6836 13.8955 25.475 10.2111 21.7906C6.52662 18.1061 4.31802 13.2007 4.00195 7.99967C4.00195 7.29243 4.2829 6.61415 4.783 6.11406C5.2831 5.61396 5.96138 5.33301 6.66862 5.33301" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M20.002 9.33301C20.7092 9.33301 21.3875 9.61396 21.8876 10.1141C22.3877 10.6142 22.6686 11.2924 22.6686 11.9997" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M20.002 4C22.1237 4 24.1585 4.84286 25.6588 6.34315C27.1591 7.84344 28.002 9.87827 28.002 12" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>

                                <a>+1 (424) 558-3701</a>
                            </li>
                        </ul>
                    </div>
                    <hr class="w-12 main-bg h-1 mb-4 mx-auto">
                    <h1 class="blue-text text-5xl font-bold text-center mb-12 px-4">{{ $t('support.subtitle') }}</h1>
                    <div class="accordions flex flex-col gap-6 px-4">
                        <Accordion v-for="item in accItems" :key='item.id' :accord="item"/>
                    </div>
                </div>
            </div>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from "../layouts/AppLayout";
import Accordion from "../components/ui/Accordion";
import {useI18n} from "vue-i18n/dist/vue-i18n";
import {mapGetters} from "vuex";

export default {
    name: "Support",
    components: {Accordion, AppLayout},
    computed: {
        ...mapGetters({
            selectedLang: 'getSelectedLanguage',
        }),
        accItems() {
            return this.selectedLang === 'jp' ? this.accordionJpItems : this.accordionEnItems
        }
    },
    data() {
        return {
            accordionEnItems: [
                {
                    title: 'How can I Return the Device',
                    id: 1,
                    body: 'Each of our pickup locations serves as a dropoff location. You could also ship it back to us. \n' +
                        'To drop off the device, all you have to do is give the device to an employee working at the location, and we\'ll handle the rest! You have 5 business days after your rental end date to return the device before late fees*. An additional $5 fee will be charged if the device is returned at a different location than the initial pickup location'
                },
                {
                    title: 'Can I Rent Last Minute?',
                    id: 2,
                    body: 'Of course! You are allowed to make the reservation whenever you\'d like. *Some locations require you to book at least 3 days before, please read each location\'s directions thoroughly.*'
                },
                {
                    title: 'Can I Change My Pickup Location?',
                    id: 3,
                    body: 'Yes! If something comes up and you need to change the pick-up location, contact us through email or call and we will make changes to your request free of charge!'
                },
                {
                    title: 'Can Someone Else PickUp For Me?',
                    id: 4,
                    body: 'Yes, however that person will need to provide proof that they are someone that can pick up on your behalf. The new pickup person must provide the order number, as well as the email that was sent out to the original customer.'
                },
                {
                    title: 'When Does My Hotspot Ship?',
                    id: 5,
                    body: 'Unless otherwise specified, we will arrange for your hotspot to arrive 2 days before the start of your plan.'
                },
                {
                    title: 'How Does Insurance Work?', id: 6, body: 'Insurance service is $2 per day.', notes: [
                        '1. Please contact us as soon as possible if the device is lost or stolen.',
                        'Insured Customer\'s fee: $100',
                        'Non-Insured Customer: $300',
                        '2. Any calls made or data used by a third party during the rental period shall be paid for by the customer.',
                        '*Any cost of broken, lost or stolen device able to be covered by your insurance.',
                    ]
                },
                {
                    title: 'Can I use SMS (Short Mail) with the WiFi router?', id: 7, body: 'SMS is not natively available.', notes: [
                        'If your phone supports Wi-Fi calling, then you will be able to make texts and calls with the WiFi router.',
                    ]
                },
                {
                    title: 'How much Data am I using?', id: 8, body: 'Please refer to the following for the main data capacity guideline.', notes: [
                    ],
                    tables: [
                        {id: 1, plans: ['Web browsing', 'About 370 pages', 'About 750 pages', 'About 1,400 pages']},
                        {id: 2, plans: ['Map searching', 'About 40 minutes', 'About 1 hour 30 minutes', 'About 2 hour 50 minutes']},
                        {id: 3, plans: ['Watch Youtube', 'About 10 minutes', 'About 20 minutes', 'About 37 minutes']},
                        {id: 4, plans: ['Chatting on SNS', 'About 7,500', 'About 15,000', 'About 28,000']},
                        {id: 5, plans: ['Calling on SNS', 'About 5 hours', 'About 10 hours ', 'About 18 hours 40 minutes']},
                        {id: 6, plans: ['Send E-mail', 'About 120', 'About 250 ', 'About 470']},
                        {id: 7, plans: ['Calling on Skype', 'About 4 hours 20 minutes', 'About 8 hours 50 minutes', 'About 16 hours 30 minutes']},
                        {id: 8, plans: ['Video Calling on Skype', 'About 50 minutes', 'About 1 hour 40 minutes', 'About 3 hour ']},
                        {id: 9, plans: ['Viewing Instagram', 'About 30 minutes', 'About 1 hour ', 'About 1 hour 50 minutes']},
                    ],
                },
                {
                    title: 'How many devices can be connected simultaneously with one Wi-Fi router?',
                    id: 9,
                    body: 'Up to 5 devices can be connected simultaneously.',
                    notes: [
                        'If multiple connections are made with one device, the daily capacity will be divided between the number of devices, resulting in slower internet speeds and faster data consumption.',
                    ],
                },
                {
                    title: 'I forgot to return the device at the airport. How can I return it?',
                    id: 10,
                    body: 'Please send it back via mail service as soon as possible ',
                    notes: [
                        'The delivery fee will be paid by the customer.',
                        'Please use a delivery method that shows the delivery history, other than ordinary mail.',
                        'Please deliver all your accessories along with the WiFi device. This includes: cables, adapters, and various optional accessories (a $10 fee will be charged for each missing accessory).\n',
                        'Please be careful not to forget to return the them. (Extension charge may apply)',
                        'You can also return at the Skygo Office in Torrance, CA or any of our other pickup locations.',
                        'Skygo WiFi ATTN:John Haruki\n' +
                        '370 Crenshaw Blvd E207\n' +
                        'Torrance CA 90503\n',
                    ],
                },
                {
                    title: 'I Can\'t Find my SSID on my WiFi settings.',
                    id: 11,
                    body: 'If you can\'t find the name \'Skygo\' (Skygo_xxxx) in your Wi-Fi settings, please look for the name \'GlocalMe\' (GlocalMe_xxxxxx) Thank you for your understanding and cooperation.',
                },
                {
                    title: 'What are the Device Specifications?',
                    id: 12,
                    body: '',
                    notes: [
                        'Max Speed- 4G LTE: DL 150mbps/ UL 50mbps',
                        'Battery- 300mAh Battery (Approx. 12-hr battery life)',
                        'Ports- Type-C (provided)',
                        'Dimensions- 4.96in • 2.6in • 0.4in (L•W•H)',
                        'Weight- 4.4oz',
                    ]
                },
            ],
            accordionJpItems: [
                {
                    title: '利用後はどのように返却したらいいですか？',
                    id: 1,
                    body: 'Skygoのブースやオフィスでは貸出だけでなく返却、返送も承ります。返却後は当社にお任せください！返却予定日から５営業日超過で延滞料金*が発生します。受け取り場所と違う場所に端末を返却する場合、＄5の追加料金が発生します。'
                },
                {
                    title: '直前に借りることはできますか？',
                    id: 2,
                    body: 'もちろんです！使いたいときにいつでも予約することができます。**ただし場所によっては3日前までに予約が必要になるので、目的地の情報をよく確認してください。'
                },
                {
                    title: '受け取り場所の変更は可能ですか？',
                    id: 3,
                    body: 'もちろんです！無料で変更することができます。ご都合が悪くなり、受け取り場所の変更をご希望の際は、メールまたは電話でお問い合わせください。'
                },
                {
                    title: '利用者以外が受け取ることはできますか？',
                    id: 4,
                    body: '可能です。代理の方は受け取る際に注文番号と予約完了メールを提示していただく必要があります。'
                },
                {title: 'WiFi端末はいつ届きますか？', id: 5, body: '特に指定がなければ、利用開始日の2日前までにお届けします。'},
                {
                    title: '補償について具体的に教えて下さい。',
                    id: 6,
                    body: '補償プランでは1日あたり2ドルで、端末の故障、紛失、盗難が補償されます。\nもし端末を紛失したり、盗まれてしまったりした場合は早急にご連絡ください。\n補償プランに入っている場合は50ドル、入っていない場合は200ドル請求させていただきます。\nまた、レンタル期間内における第三者による電話料や通信料はお客様のご負担となります。 補償プランは一日あたり＄2かかります。',
                    notes: [
                        '補償プランは一日あたり＄2かかります。',
                        '1. 端末の紛失や盗難があれば早急にご連絡ください。',
                        '補償に入っている方の自己負担:＄50',
                        '入っていない方の自己負担:＄200',
                        '2. レンタル期間中の第三者による通話・データ通信料はお客様のご負担となります。',
                        '*端末の破損や紛失、盗難に関する費用は補償されます。',
                    ]
                },
                {
                    title: 'SMS（ショートメール）はWiFiルーターの通信で使えますか？',
                    id: 7,
                    body: '申し訳ございません、基本的にはご利用になれません。',
                    notes: [
                        'お客様の契約回線によります、ご契約の携帯電話会社様への確認をお願いいたします。',
                        'SMS（ショートメール）は、電話番号宛てにSMSを送るため、ご契約の携帯電話会社さまの通信が必要です。',
                        '海外でSMSの送受信の方法や料金に関しては、ご利用になられている携帯電話会社さまへお問い合わせください。',
                    ]
                },
                {
                    title: 'データ通信量の目安を教えてください。',
                    id: 8,
                    body: '主なデータ容量の目安は以下をご参照ください。',
                    notes: [
                        'お客様の契約回線によります、ご契約の携帯電話会社様への確認をお願いいたします。',
                        'SMS（ショートメール）は、電話番号宛てにSMSを送るため、ご契約の携帯電話会社さまの通信が必要です。',
                        '海外でSMSの送受信の方法や料金に関しては、ご利用になられている携帯電話会社さまへお問い合わせください。',
                    ],
                    tables: [
                        {id: 1, plans: ['WEB閲覧', '約370ページ', '約750ページ', '約1,400ページ']},
                        {id: 2, plans: ['マップ検索', '約40分', '約1時間30分', '約2時間50分']},
                        {id: 3, plans: ['YouTube閲覧', '約10分', '約20分', '約37分']},
                        {id: 4, plans: ['トーク', '約7,500通', '約15,000通', '約28,000通']},
                        {id: 5, plans: ['音声通話', '約5時間', '約10時間 ', '約18時間40分']},
                        {id: 6, plans: ['メール送信', '約120通', '約250通 ', '約470通']},
                        {id: 7, plans: ['音声通話', '約4時間20分', '約8時間50分', '約16時間30分']},
                        {id: 8, plans: ['ビデオ通話', '約50分', '約1時間40分', '約3時間']},
                        {id: 9, plans: ['Instagram閲覧', '約30分', '約1時間', '約1時間50分']},
                    ],
                },
                {
                    title: 'Wi-Fi ルーター1台で何台（何人）まで同時接続が可能ですか？',
                    id: 9,
                    body: '5台（5人）まで同時接続が可能です。',
                    notes: [
                        '1台で複数接続する場合は、1日の容量を人数や台数で分け合ってご利用していただくため、ご利用いただける容量は少なくなります。',
                        '複数人でのご利用は、超大容量や無制限プランを選択ください。',
                        '通信容量をオーバーすると、速度低下もしくは通信できなくなる可能性がありますのでご注意ください。',
                    ],
                },
                {
                    title: '空港で返しそびれたのですが、どうしたらいいですか？',
                    id: 10,
                    body: 'なるべく早めに宅配でご返送ください。',
                    notes: [
                        '宅配料金はお客様負担となりますので、予めご了承をお願いいたします。',
                        '配送は、普通郵便以外で、配送履歴の分かる配送方法にてお願いいたします。',
                        'WiFi機器・ケーブル・アダプタ・各種オプション品など。レンタル品一式のご返却をお願いいたします。',
                        '返却もれにご注意ください。（延長料金がかかる場合がございます。）',
                        'もしくはトーランスオフィスにてご返却ください。',
                        'Skygo WiFi ATTN:John Haruki\n' +
                        '370 Crenshaw Blvd E207\n' +
                        'Torrance CA 90503\n',
                    ],
                },
                {
                    title: 'スマホ・タブレット上のWi-Fi設定でデバイスが見つからず、Wi-Fiが使えない',
                    id: 11,
                    body: 'デバイスのオートアップデートにより、デバイス名が「Skygo_○○○○」ではなく「GlocalMe_○○○○○○」になっている場合がございます。Wi-Fiにつなげる際、Skygoの名前が見つからない時は、GlocalMeのお名前をお探しいただきますようよろしくお願い申し上げます。',
                },
            ],
        }
    },
    setup() {
        const {t} = useI18n({
            useScope: 'global'
        })

        return {t}
    },
}
</script>

<style lang="scss">
.support-bg {
    background-image: url("../assets/png/support-banner.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 170px 0;
}

.support-wrapper {
    background: linear-gradient(180.02deg, rgba(230, 247, 255, 0) 12.02%, rgba(99, 205, 255, 0.7) 87.98%);

    .accordions {
        padding-bottom: 200px;
        position: relative;

        &:before {
            content: '';
            background-image: url("../assets/png/cloud-support.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 180px;
        }
    }
}
</style>